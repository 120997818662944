<script setup lang="ts">
import { ref, onBeforeMount } from 'vue'

import BaseModal from './BaseModal.vue';
import BaseInputField from '../atoms/BaseInputField.vue';
import ListIntegrations from '../molecules/ListIntegrations.vue';

import api from '@/services/api';
import { useSiteStore } from '@/stores/site';
import notification, { type ToastId } from '@/services/notification';
import modal from '@/services/modal';

const siteStore = useSiteStore();

const isOpen = ref<boolean>(false);

const titleModal = ref<string>('Adicionar Rede Social');
const actionModal = ref<string>('Adicionar');

const userCodes = ref<any>({
    pixelId: '',
    analyticsId: '',
    analyticsIdV4: ''
});

const customIntegrationsList = ref<any>([]);

const customIntegrationHandle = ref<any>({
    id: null,
    link: '',
    api: 0,
})

const options = ref<any>(
    [
        {
            id: 1,
            name: 'Instagram'
        },
        {
            id: 2,
            name: 'Foursquare'
        },
        {
            id: 3,
            name: 'Behance'
        },
        {
            id: 4,
            name: 'Facebook'
        },
        {
            id: 5,
            name: 'Youtube'
        },
        {
            id: 6,
            name: 'Twitter'
        },
        {
            id: 10,
            name: 'Whatsapp'
        },
        {
            id: 11,
            name: 'Vimeo'
        },
        {
            id: 12,
            name: 'TikTok'
        },
        {
            id: 13,
            name: 'LinkedIn'
        },
        {
            id: 14,
            name: 'Pinterest'
        }
    ]
)

const getCodes = async () => {
    try {
        const response = await api.get(`site/${siteStore.id}/analytics`);

        const { status, data } = await response.json();

        if (status != '1') {
            throw new Error('Erro ao buscar códigos');
        }

        userCodes.value.pixelId = data.pixelId;

        userCodes.value.analyticsId = data.analyticsId;

        userCodes.value.analyticsIdV4 = data.analyticsIdV4;
    } catch (error) {
        console.error(error);
    }
}

const updateCodes = async () => {
    const id: ToastId = notification.loading('Atualizando códigos');

    try {
        const response = await api.post(
            `site/${siteStore.id}/analytics/update`,
            JSON.stringify(userCodes.value)
        );

        const { status } = await response.json();

        if (status != '1') {
            throw new Error('Erro ao atualizar códigos');
        }

        console.log('Códigos atualizados com sucesso');

        notification.update(id, 'Códigos atualizados com sucesso', 'success');
    } catch (error) {
        console.error(error);

        notification.update(id, 'Erro ao atualizar códigos', 'error');
    }
}

const fetchCustomIntegrations = async () => {
    try {
        const response = await api.get(`site/${siteStore.id}/link`);

        const { status, data } = await response.json();

        if (status != '1') {
            throw new Error('Erro ao buscar códigos');
        }

        customIntegrationsList.value = data;

        console.log('customIntegrationsList', customIntegrationsList.value);
    } catch (error) {
        console.error(error);   
    }
}

const addNewCustomIntegration = async () => {
    const id: ToastId = notification.loading('Adicionando Rede Social');
    
    try {
        const response = await api.post(
            `site/${siteStore.id}/link/create`,
            JSON.stringify(customIntegrationHandle.value)
        );

        const { status } = await response.json();

        if (status != '1') {
            throw new Error('Erro ao criar Rede Social');
        }

        fetchCustomIntegrations();

        console.log('customIntegrationsList', customIntegrationsList.value);

        notification.update(id, 'Rede Social adicionada com sucesso', 'success');

        customIntegrationHandle.value = {
            id: null,
            link: '',
            api: 0,
        }
    } catch (error) {
        console.error(error);

        notification.update(id, 'Erro ao criar Rede Social', 'error');
    }

    onCloseModal();
}

const updateCustomIntegration = async () => {
    const id: ToastId = notification.loading('Atualizando Rede Social');
    
    try {
        const response = await api.post(
            `site/${siteStore.id}/link/${customIntegrationHandle.value.id}/update`,
            JSON.stringify(customIntegrationHandle.value)
        );

        const { status } = await response.json();

        if (status != '1') {
            throw new Error('Erro ao atualizar Rede Social');
        }

        fetchCustomIntegrations();

        console.log('customIntegrationsList', customIntegrationsList.value);

        notification.update(id, 'Rede Social atualizada com sucesso', 'success');

        customIntegrationHandle.value = {
            id: null,
            link: '',
            api: 0,
        }
    } catch (error) {
        console.error(error);

        notification.update(id, 'Erro ao atualizar Rede Social', 'error');
    }

    onCloseModal();
}

const onCloseModal = () => {
    isOpen.value = false;
}

const onAddNewCustomIntegration = () => {
    titleModal.value = 'Adicionar Rede Social';
    actionModal.value = 'Adicionar';

    customIntegrationHandle.value = {
        id: null,
        link: '',
        api: 0,
    }

    isOpen.value = true;
}

const onEditIntegration = (item: any) => {
    titleModal.value = 'Editar Rede Social';
    actionModal.value = 'Salvar';

    customIntegrationHandle.value = item;

    isOpen.value = true;
}

onBeforeMount(async () => {
    console.log('DashboardSidebarItemIntegrations');

    await getCodes();
    await fetchCustomIntegrations();
});

const handleActionModal = () => {
    if (actionModal.value == 'Adicionar') {
        addNewCustomIntegration();
    } else {
        updateCustomIntegration();
    }
}

const handleDeleteIntegration = async (item: any) => {
    const id: ToastId = notification.loading('Excluindo Rede Social');
    
    try {
        const response = await api.post(
            `site/${siteStore.id}/link/${item.id}/delete`,
            null
        );

        const { status } = await response.json();

        if (status != '1') {
            throw new Error('Erro ao excluir Rede Social');
        }

        fetchCustomIntegrations();

        console.log('customIntegrationsList', customIntegrationsList.value);

        notification.update(id, 'Rede Social excluída com sucesso', 'success');
    } catch (error) {
        console.error(error);

        notification.update(id, 'Erro ao excluir Rede Social', 'error');
    }
}

const onDeleteIntegration = async (item: any) => {
    const title = 'Confirmar Exclusão'
    const message = 'Deseja realmente excluir este link?'
    const type = 'danger'
    const confirm = 'Sim, excluir'
    const cancel = 'Não, voltar'

    const result = await modal.confirmation(title, message, {
        type: type,
        textConfirm: confirm,
        textCancel: cancel
    })

    if (!result) {
        return
    }

    await handleDeleteIntegration(item);
}
</script>

<template>
    <base-modal
        :title="titleModal"
        :open-watch="isOpen"
        @modal-closed="() => isOpen = false"
        size="custom"
        height="h-[20rem]"
        width="w-[30rem]"
        rounded="2xl"
    >
        <template #body>
            <div class="h-full flex flex-col justify-between">
                <div class="flex-1 space-y-3">
                    <base-input-field label="Rede Social" id="option" type="select">
                        <select
                            id="option"
                            v-model="customIntegrationHandle.api"
                            class="w-full"
                        >
                            <option>Selecione uma rede social</option>
                            <option
                                v-for="option in options"
                                :key="option.id"
                                :value="option.id"
                                :selected="option.id == customIntegrationHandle.api"
                            >
                                {{ option.name }}
                            </option>
                        </select>
                    </base-input-field>

                    <base-input-field label="Seu endereço" id="link">
                        <input 
                            type="text"
                            name="link"
                            v-model="customIntegrationHandle.link"
                        >
                    </base-input-field>
                </div>

                <div class="flex justify-end mt-4 space-x-2">
                    <button
                        @click="() => onCloseModal()"
                        class="px-4 py-2 text-sm text-[#D9D9D9] bg-transparent rounded-full hover:text-gray-500 outline-none"
                    >
                        Cancelar
                    </button>

                    <button
                        @click="() => handleActionModal()"
                        class="bg-[var(--main-color)] text-white px-4 py-2 text-sm font-medium rounded-full outline-none"
                    >
                        {{ actionModal }}
                    </button>
                </div>
            </div>
        </template>
    </base-modal>

    <div class="w-full px-6 space-y-3">
        <h2 class="text-2xl font-bold">Integrações</h2>
        
        <list-integrations />

        <div class="flex flex-col space-y-3">
            <div class="flex flex-col">
                <label class="text-[10px] font-semibold text-gray-400" for="">
                    Redes sociais
                </label>

                <div class="space-y-3">
                    <div 
                        v-for="item in customIntegrationsList"
                        :key="item.id"
                        class="relative flex justify-between items-center w-full h-10 border-b-[1px] border-[#F5F5F5]"
                    >
                        <span>
                            {{ item.link }}
                        </span>

                        <div class="flex space-x-3">
                            <button 
                                @click="() => onEditIntegration(item)"
                                class="right-0 top-1/2 -translate-y-1/2"
                            >
                                <span class="sr-only">
                                    Edit
                                </span>

                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
                                </svg>
                            </button>

                            <button 
                                @click="() => onDeleteIntegration(item)"
                                class="right-0 top-1/2 -translate-y-1/2"
                            >
                                <span class="sr-only">
                                    Delete
                                </span>

                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>

                <button
                    @click="() => onAddNewCustomIntegration()"
                    class="mt-3 text-gray-500"
                >
                    + Adicionar
                </button>
            </div>

            <div class="space-y-2">
                <div class="w-full flex flex-col space-y-1">
                    <label class="text-[10px] font-semibold text-gray-400" for="">
                        ID de acompanhamento
                        <a 
                            href="https://analytics.google.com" 
                            class="text-blue-500"
                            target="_blank">
                            Google Analytics
                        </a>
                    </label>

                    <input
                        type="text"
                        name=""
                        id=""
				        placeholder="AA-000000000-0"
                        v-maska
                        data-maska="**-*********-*"
                        v-model="userCodes.analyticsId"
                        class="h-10 border-b-[1px] focus:border-[var(--main-color)] outline-none bg-transparent"
                    />
                </div>

                <div class="w-full flex flex-col space-y-1">
                    <label class="text-[10px] font-semibold text-gray-400" for="">
                        ID de acompanhamento
                        <a 
                            href="https://analytics.google.com" 
                            class="text-blue-500"
                            target="_blank">
                            Google Analytics V4
                        </a>
                    </label>

                    <input
                        type="text"
				        placeholder="A-AAAAAAAAAA"
                        v-maska
                        data-maska="*-**********"
                        v-model="userCodes.analyticsIdV4"
                        class="h-10 border-b-[1px] focus:border-[var(--main-color)] outline-none bg-transparent"
                    />
                </div>

                <div class="w-full flex flex-col space-y-1">
                    <label class="text-[10px] font-semibold text-gray-400" for="">
                        Facebook
                        <a
                            href="https://www.facebook.com/events_manager2/list"
                            class="text-blue-500"
                            target="_blank"
                        >
                            Pixel
                        </a>
                    </label>

                    <input
                        type="text"
                        placeholder="0000000000000000"
                        v-maska
                        data-maska="################"
                        v-model="userCodes.pixelId"
                        class="h-10 border-b-[1px] focus:border-[var(--main-color)] outline-none bg-transparent"
                    />
                </div>
            </div>

            <button
                @click="() => updateCodes()"
                class="w-full bg-[var(--main-color)] py-2 text-white font-semibold rounded-full"
            >
                Aplicar
            </button>
        </div>
    </div>
</template>

<style></style>
